/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "amplifyIdentityBrokerApi",
            "endpoint": "https://xfykvh39i0.execute-api.eu-central-1.amazonaws.com/preview",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_mqwJaohbH",
    "aws_user_pools_web_client_id": "40kg5bf8thc83h6vo0ipvgk0rp",
    "oauth": {
        "domain": "amplifybroker-effe-preview.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev-sso.effe.it",
        "redirectSignOut": "https://dev-sso.effe.it/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "idbroker-effe-bucket-preview",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dg4mmxpu66xr3.cloudfront.net",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "amplifyIdentityBrokerClients-preview",
            "region": "eu-central-1"
        },
        {
            "tableName": "amplifyIdentityBrokerCodes-preview",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
