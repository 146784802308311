import { Authenticator, Button, CheckboxField, Grid, Heading, Image, Label, Link, PasswordField, PhoneNumberField, SelectField, Text, TextField, View, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import logo from "./../assets/Logos/effe-logo.svg"
import { I18n } from 'aws-amplify/utils';
import Lottie from 'react-lottie';
import animationDataLogin from './../assets/Lotties/onboarding_login.json';
import animationDataResetPsw from './../assets/Lotties/onboarding_password.json';
import animationDataResetRegistration from './../assets/Lotties/onboarding_registration.json';


const components = {
  Header() {
    const { tokens } = useTheme();
    const { route } = useAuthenticator(context => [context.route]);

    const dimensionLottie = window?.innerWidth <= 600 ? 250 : 315
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Effe logo"
          src={logo}
          id="logo-effe"
          style={{ maxWidth: 150 }}
        />

        <div className="lottie-container" style={{ marginBottom: 25 }}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: route === "signUp" ? animationDataResetRegistration : route === "forgotPassword" ? animationDataResetPsw : animationDataLogin,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={dimensionLottie}
            width={dimensionLottie}
          />
        </div>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" marginTop={100} padding={tokens.space.large}>
        <Text fontSize={16} color={"#00FFA8"}>{`© ${new Date().getFullYear()} Effegibi – P.I. 03914050400`}</Text>
      </View>
    );
  },

  SignUp: {
    FormFields() {
      const auth = useAuthenticator();
      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          {/* <Authenticator.SignUp.FormFields /> */}

          <TextField label={I18n.get('Firstname')} name="given_name" placeholder={I18n.get('Enter_Firstname')}
            errorMessage={auth?.validationErrors.given_name}
            hasError={!!auth?.validationErrors.given_name} />

          <TextField label={I18n.get('Surname')} name="family_name" placeholder={I18n.get('Enter_Surname')}
            errorMessage={auth?.validationErrors.family_name}
            hasError={!!auth?.validationErrors.family_name} />

          <TextField label={I18n.get('Email')} type="email" name="email" placeholder={I18n.get('Enter_Email')}
            errorMessage={auth?.validationErrors.email}
            hasError={!!auth?.validationErrors.email} />

          <PhoneNumberField label={`${I18n.get('Phone')} (${I18n.get('Optional')})`} name="phone_number" placeholder={I18n.get('Enter_Phone')}
            defaultDialCode="+39"
            dialCodeName="custom:dial_code_phone_number" />

          <PasswordField label={I18n.get('Password')} name="password" placeholder={I18n.get('Enter_Password')}
            errorMessage={auth?.validationErrors.password}
            hasError={!!auth?.validationErrors.password} />

          <PasswordField label={I18n.get("Confirm Password")} name="confirmpassword" placeholder={I18n.get('Enter_ConfirmPassword')}
            errorMessage={auth?.validationErrors.confirmpassword}
            hasError={!!auth?.validationErrors.confirmpassword} />

          <SelectField name="custom:country" label={I18n.get('Country')}
            errorMessage={auth?.validationErrors["custom:country"]}
            hasError={!!auth?.validationErrors["custom:country"]}>
            <option value="">{I18n.get('Select_Country')}</option>

            <option value="Afghanistan">{I18n.get('Afghanistan')}</option>
            <option value="Albania">{I18n.get('Albania')}</option>
            <option value="Algeria">{I18n.get('Algeria')}</option>
            <option value="Andorra">{I18n.get('Andorra')}</option>
            <option value="Angola">{I18n.get('Angola')}</option>
            <option value="Anguilla">{I18n.get('Anguilla')}</option>
            <option value="Antigua and Barbuda">{I18n.get('Antigua and Barbuda')}</option>
            <option value="Argentina">{I18n.get('Argentina')}</option>
            <option value="Armenia">{I18n.get('Armenia')}</option>
            <option value="Aruba">{I18n.get('Aruba')}</option>
            <option value="Australia">{I18n.get('Australia')}</option>
            <option value="Austria">{I18n.get('Austria')}</option>
            <option value="Azerbaijan">{I18n.get('Azerbaijan')}</option>
            <option value="Bahamas">{I18n.get('Bahamas')}</option>
            <option value="Bahrain">{I18n.get('Bahrain')}</option>
            <option value="Bangladesh">{I18n.get('Bangladesh')}</option>
            <option value="Barbados">{I18n.get('Barbados')}</option>
            <option value="Belarus">{I18n.get('Belarus')}</option>
            <option value="Belgium">{I18n.get('Belgium')}</option>
            <option value="Belize">{I18n.get('Belize')}</option>
            <option value="Benin">{I18n.get('Benin')}</option>
            <option value="Bermuda">{I18n.get('Bermuda')}</option>
            <option value="Bhutan">{I18n.get('Bhutan')}</option>
            <option value="Bolivia">{I18n.get('Bolivia')}</option>
            <option value="Bosnia and Herzegovina">{I18n.get('Bosnia and Herzegovina')}</option>
            <option value="Botswana">{I18n.get('Botswana')}</option>
            <option value="Brazil">{I18n.get('Brazil')}</option>
            <option value="British Virgin Islands">{I18n.get('British Virgin Islands')}</option>
            <option value="Brunei Darussalam">{I18n.get('Brunei Darussalam')}</option>
            <option value="Bulgaria">{I18n.get('Bulgaria')}</option>
            <option value="Burkina Faso">{I18n.get('Burkina Faso')}</option>
            <option value="Burundi">{I18n.get('Burundi')}</option>
            <option value="Cambodia">{I18n.get('Cambodia')}</option>
            <option value="Cameroon">{I18n.get('Cameroon')}</option>
            <option value="Canada">{I18n.get('Canada')}</option>
            <option value="Cape Verde">{I18n.get('Cape Verde')}</option>
            <option value="Cayman Islands">{I18n.get('Cayman Islands')}</option>
            <option value="Central African Republic">{I18n.get('Central African Republic')}</option>
            <option value="Chad">{I18n.get('Chad')}</option>
            <option value="Chile">{I18n.get('Chile')}</option>
            <option value="China">{I18n.get('China')}</option>
            <option value="Colombia">{I18n.get('Colombia')}</option>
            <option value="Comoros">{I18n.get('Comoros')}</option>
            <option value="Congo">{I18n.get('Congo')}</option>
            <option value="Cook Islands (NZ)">{I18n.get('Cook Islands (NZ)')}</option>
            <option value="Costa Rica">{I18n.get('Costa Rica')}</option>
            <option value="Croatia">{I18n.get('Croatia')}</option>
            <option value="Cuba">{I18n.get('Cuba')}</option>
            <option value="Curaçao">{I18n.get('Curaçao')}</option>
            <option value="Cyprus">{I18n.get('Cyprus')}</option>
            <option value="Czech Republic">{I18n.get('Czech Republic')}</option>
            <option value="Côte d'Ivoire">{I18n.get('Côte d\'Ivoire')}</option>
            <option value="Democratic Republic of the Congo">{I18n.get('Democratic Republic of the Congo')}</option>
            <option value="Denmark">{I18n.get('Denmark')}</option>
            <option value="Djibouti">{I18n.get('Djibouti')}</option>
            <option value="Dominica">{I18n.get('Dominica')}</option>
            <option value="Dominican Republic">{I18n.get('Dominican Republic')}</option>
            <option value="Ecuador">{I18n.get('Ecuador')}</option>
            <option value="Egypt">{I18n.get('Egypt')}</option>
            <option value="El Salvador">{I18n.get('El Salvador')}</option>
            <option value="Equatorial Guinea">{I18n.get('Equatorial Guinea')}</option>
            <option value="Eritrea">{I18n.get('Eritrea')}</option>
            <option value="Estonia">{I18n.get('Estonia')}</option>
            <option value="Eswatini">{I18n.get('Eswatini')}</option>
            <option value="Ethiopia">{I18n.get('Ethiopia')}</option>
            <option value="Falkland Islands (Malvinas)">{I18n.get('Falkland Islands (Malvinas)')}</option>
            <option value="Far East">{I18n.get('Far East')}</option>
            <option value="Faroe Islands">{I18n.get('Faroe Islands')}</option>
            <option value="Federated States of Micronesia">{I18n.get('Federated States of Micronesia')}</option>
            <option value="Fiji">{I18n.get('Fiji')}</option>
            <option value="Finland">{I18n.get('Finland')}</option>
            <option value="France">{I18n.get('France')}</option>
            <option value="French Polynesia">{I18n.get('French Polynesia')}</option>
            <option value="French Southern Territories">{I18n.get('French Southern Territories')}</option>
            <option value="Gabon">{I18n.get('Gabon')}</option>
            <option value="Gambia">{I18n.get('Gambia')}</option>
            <option value="Georgia">{I18n.get('Georgia')}</option>
            <option value="Germany">{I18n.get('Germany')}</option>
            <option value="Ghana">{I18n.get('Ghana')}</option>
            <option value="Gibraltar">{I18n.get('Gibraltar')}</option>
            <option value="Greece">{I18n.get('Greece')}</option>
            <option value="Greenland">{I18n.get('Greenland')}</option>
            <option value="Grenada">{I18n.get('Grenada')}</option>
            <option value="Guatemala">{I18n.get('Guatemala')}</option>
            <option value="Guernsey">{I18n.get('Guernsey')}</option>
            <option value="Guinea">{I18n.get('Guinea')}</option>
            <option value="Guinea-Bissau">{I18n.get('Guinea-Bissau')}</option>
            <option value="Guyana">{I18n.get('Guyana')}</option>
            <option value="Haiti">{I18n.get('Haiti')}</option>
            <option value="Honduras">{I18n.get('Honduras')}</option>
            <option value="Hungary">{I18n.get('Hungary')}</option>
            <option value="Iceland">{I18n.get('Iceland')}</option>
            <option value="India">{I18n.get('India')}</option>
            <option value="Indonesia">{I18n.get('Indonesia')}</option>
            <option value="Iran">{I18n.get('Iran')}</option>
            <option value="Iraq">{I18n.get('Iraq')}</option>
            <option value="Ireland">{I18n.get('Ireland')}</option>
            <option value="Isle of Man">{I18n.get('Isle of Man')}</option>
            <option value="Israel">{I18n.get('Israel')}</option>
            <option value="Italia">{I18n.get('Italia')}</option>
            <option value="Jamaica">{I18n.get('Jamaica')}</option>
            <option value="Japan">{I18n.get('Japan')}</option>
            <option value="Jersey">{I18n.get('Jersey')}</option>
            <option value="Jordan">{I18n.get('Jordan')}</option>
            <option value="Kazakhstan">{I18n.get('Kazakhstan')}</option>
            <option value="Kenya">{I18n.get('Kenya')}</option>
            <option value="Kiribati">{I18n.get('Kiribati')}</option>
            <option value="Kosovo">{I18n.get('Kosovo')}</option>
            <option value="Kuwait">{I18n.get('Kuwait')}</option>
            <option value="Kyrgyzstan">{I18n.get('Kyrgyzstan')}</option>
            <option value="Laos">{I18n.get('Laos')}</option>
            <option value="Latvia">{I18n.get('Latvia')}</option>
            <option value="Lebanon">{I18n.get('Lebanon')}</option>
            <option value="Lesotho">{I18n.get('Lesotho')}</option>
            <option value="Liberia">{I18n.get('Liberia')}</option>
            <option value="Libya">{I18n.get('Libya')}</option>
            <option value="Liechtenstein">{I18n.get('Liechtenstein')}</option>
            <option value="Lithuania">{I18n.get('Lithuania')}</option>
            <option value="Luxembourg">{I18n.get('Luxembourg')}</option>
            <option value="North Macedonia">{I18n.get('North Macedonia')}</option>
            <option value="Madagascar">{I18n.get('Madagascar')}</option>
            <option value="Malawi">{I18n.get('Malawi')}</option>
            <option value="Malaysia">{I18n.get('Malaysia')}</option>
            <option value="Maldives">{I18n.get('Maldives')}</option>
            <option value="Mali">{I18n.get('Mali')}</option>
            <option value="Malta">{I18n.get('Malta')}</option>
            <option value="Marshall Islands">{I18n.get('Marshall Islands')}</option>
            <option value="Mauritania">{I18n.get('Mauritania')}</option>
            <option value="Mauritius">{I18n.get('Mauritius')}</option>
            <option value="Mexico">{I18n.get('Mexico')}</option>
            <option value="Moldova">{I18n.get('Moldova')}</option>
            <option value="Monaco">{I18n.get('Monaco')}</option>
            <option value="Mongolia">{I18n.get('Mongolia')}</option>
            <option value="Montenegro">{I18n.get('Montenegro')}</option>
            <option value="Montserrat">{I18n.get('Montserrat')}</option>
            <option value="Morocco">{I18n.get('Morocco')}</option>
            <option value="Mozambique">{I18n.get('Mozambique')}</option>
            <option value="Myanmar/Burma">{I18n.get('Myanmar/Burma')}</option>
            <option value="Namibia">{I18n.get('Namibia')}</option>
            <option value="Nauru">{I18n.get('Nauru')}</option>
            <option value="Nepal">{I18n.get('Nepal')}</option>
            <option value="Netherlands">{I18n.get('Netherlands')}</option>
            <option value="New Caledonia">{I18n.get('New Caledonia')}</option>
            <option value="New Zealand">{I18n.get('New Zealand')}</option>
            <option value="Nicaragua">{I18n.get('Nicaragua')}</option>
            <option value="Niger">{I18n.get('Niger')}</option>
            <option value="Nigeria">{I18n.get('Nigeria')}</option>
            <option value="North Korea">{I18n.get('North Korea')}</option>
            <option value="Norway">{I18n.get('Norway')}</option>
            <option value="Oman">{I18n.get('Oman')}</option>
            <option value="Pakistan">{I18n.get('Pakistan')}</option>
            <option value="Palau">{I18n.get('Palau')}</option>
            <option value="Palestine">{I18n.get('Palestine')}</option>
            <option value="Panama">{I18n.get('Panama')}</option>
            <option value="Papua New Guinea">{I18n.get('Papua New Guinea')}</option>
            <option value="Paraguay">{I18n.get('Paraguay')}</option>
            <option value="Peru">{I18n.get('Peru')}</option>
            <option value="Philippines">{I18n.get('Philippines')}</option>
            <option value="Pitcairn">{I18n.get('Pitcairn')}</option>
            <option value="Poland">{I18n.get('Poland')}</option>
            <option value="Portugal">{I18n.get('Portugal')}</option>
            <option value="Puerto Rico">{I18n.get('Puerto Rico')}</option>
            <option value="Qatar">{I18n.get('Qatar')}</option>
            <option value="Romania">{I18n.get('Romania')}</option>
            <option value="Russian Federation">{I18n.get('Russian Federation')}</option>
            <option value="Rwanda">{I18n.get('Rwanda')}</option>
            <option value="Saint Barthelemy">{I18n.get('Saint Barthelemy')}</option>
            <option value="Saint Helena">{I18n.get('Saint Helena')}</option>
            <option value="Saint Kitts and Nevis">{I18n.get('Saint Kitts and Nevis')}</option>
            <option value="Saint Lucia">{I18n.get('Saint Lucia')}</option>
            <option value="Saint Martin (FR)">{I18n.get('Saint Martin (FR)')}</option>
            <option value="Saint Pierre and Miquelon">{I18n.get('Saint Pierre and Miquelon')}</option>
            <option value="Saint Vincent and the Grenadines">{I18n.get('Saint Vincent and the Grenadines')}</option>
            <option value="Samoa">{I18n.get('Samoa')}</option>
            <option value="San Marino">{I18n.get('San Marino')}</option>
            <option value="San Marino (RSM)">{I18n.get('San Marino (RSM)')}</option>
            <option value="Sao Tome and Principe">{I18n.get('Sao Tome and Principe')}</option>
            <option value="Sark">{I18n.get('Sark')}</option>
            <option value="Saudi Arabia">{I18n.get('Saudi Arabia')}</option>
            <option value="Senegal">{I18n.get('Senegal')}</option>
            <option value="Serbia">{I18n.get('Serbia')}</option>
            <option value="Seychelles">{I18n.get('Seychelles')}</option>
            <option value="Sierra Leone">{I18n.get('Sierra Leone')}</option>
            <option value="Singapore">{I18n.get('Singapore')}</option>
            <option value="Slovakia">{I18n.get('Slovakia')}</option>
            <option value="Slovenia">{I18n.get('Slovenia')}</option>
            <option value="Solomon Islands">{I18n.get('Solomon Islands')}</option>
            <option value="Somalia">{I18n.get('Somalia')}</option>
            <option value="South Africa">{I18n.get('South Africa')}</option>
            <option value="South Korea">{I18n.get('South Korea')}</option>
            <option value="South Sudan">{I18n.get('South Sudan')}</option>
            <option value="Spain">{I18n.get('Spain')}</option>
            <option value="Sri Lanka">{I18n.get('Sri Lanka')}</option>
            <option value="St Maarten (NL)">{I18n.get('St Maarten (NL)')}</option>
            <option value="Sudan">{I18n.get('Sudan')}</option>
            <option value="Suriname">{I18n.get('Suriname')}</option>
            <option value="Sweden">{I18n.get('Sweden')}</option>
            <option value="Switzerland">{I18n.get('Switzerland')}</option>
            <option value="Syria">{I18n.get('Syria')}</option>
            <option value="Taiwan">{I18n.get('Taiwan')}</option>
            <option value="Tajikistan">{I18n.get('Tajikistan')}</option>
            <option value="Tanzania">{I18n.get('Tanzania')}</option>
            <option value="Thailand">{I18n.get('Thailand')}</option>
            <option value="Timor Leste">{I18n.get('Timor Leste')}</option>
            <option value="Togo">{I18n.get('Togo')}</option>
            <option value="Tonga">{I18n.get('Tonga')}</option>
            <option value="Trinidad and Tobago">{I18n.get('Trinidad and Tobago')}</option>
            <option value="Tunisia">{I18n.get('Tunisia')}</option>
            <option value="Turkey">{I18n.get('Turkey')}</option>
            <option value="Turkmenistan">{I18n.get('Turkmenistan')}</option>
            <option value="Turks and Caicos Islands">{I18n.get('Turks and Caicos Islands')}</option>
            <option value="Tuvalu">{I18n.get('Tuvalu')}</option>
            <option value="Uganda">{I18n.get('Uganda')}</option>
            <option value="Ukraine">{I18n.get('Ukraine')}</option>
            <option value="United Arab Emirates">{I18n.get('United Arab Emirates')}</option>
            <option value="United Kingdom">{I18n.get('United Kingdom')}</option>
            <option value="United States of America">{I18n.get('United States of America')}</option>
            <option value="Uruguay">{I18n.get('Uruguay')}</option>
            <option value="Uzbekistan">{I18n.get('Uzbekistan')}</option>
            <option value="Vanuatu">{I18n.get('Vanuatu')}</option>
            <option value="Vatican City State">{I18n.get('Vatican City State')}</option>
            <option value="Venezuela">{I18n.get('Venezuela')}</option>
            <option value="Vietnam">{I18n.get('Vietnam')}</option>
            <option value="Wallis and Futuna Islands">{I18n.get('Wallis and Futuna Islands')}</option>
            <option value="Western Sahara">{I18n.get('Western Sahara')}</option>
            <option value="Yemen">{I18n.get('Yemen')}</option>
            <option value="Zambia">{I18n.get('Zambia')}</option>
            <option value="Zimbabwe">{I18n.get('Zimbabwe')}</option>

          </SelectField>

          <SelectField name="custom:effe_profile" label={I18n.get('Profile')}
            placeholder={I18n.get('Select_Profile')}
            errorMessage={auth?.validationErrors["custom:effe_profile"]}
            hasError={!!auth?.validationErrors["custom:effe_profile"]}>
            <option value="Privato">{I18n.get('Profile_Privato')}</option>
            <option value="Architetto/Designer">{I18n.get('Profile_Architetto_Designer')}</option>
            <option value="Ingegnere/Progettista">{I18n.get('Profile_Ingegnere_Progettista')}</option>
            <option value="Gestore Hotel/SPA/Palestra">{I18n.get('Profile_Gestore_Hotel_SPA_Gym')}</option>
            <option value="Costruttore">{I18n.get('Profile_Costruttore')}</option>
            <option value="Contractor">{I18n.get('Profile_General_Contractor')}</option>
            <option value="Rivenditore">{I18n.get('Profile_Rivenditore')}</option>
            <option value="Agenzia">{I18n.get('Profile_Agenzia')}</option>
            <option value="Centro Assistenza/Installatore">{I18n.get('Profile_Centro_Assistenza_Installatore')}</option>
            <option value="Altro">{I18n.get('Profile_Altro')}</option>
          </SelectField>

          <TextField label="Company/Studio" name="custom:effe_company" placeholder={I18n.get("Enter_Company")}
            errorMessage={auth?.validationErrors["custom:effe_company"]}
            hasError={!!auth?.validationErrors["custom:effe_company"]} />


          {/* Append & require Terms and Conditions field to sign up  */}
          <CheckboxField
            errorMessage={auth?.validationErrors["custom:accept_privacy"]}
            hasError={!!auth?.validationErrors["custom:accept_privacy"]}
            name="custom:accept_privacy"
            value="yes"
            defaultValue={undefined}
            label={<Label fontSize={14}>{I18n.get("I_read")} <Link href="https://www.effe.it/privacy-policy/" target="_blank">{I18n.get("Informativa_privacy")}</Link> {I18n.get("Accetto_privacy")}</Label>}
          />
        </>
      );
    }
  }
};

export default components;