import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import App from './App';

import * as serviceWorker from "./serviceWorker";
import awsconfig from "./aws-exports";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import RefreshToken from "./RefreshToken";

var Config = require("Config");

let amplifyConfig = {
	...awsconfig,
	Auth: {
		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_PASSWORD_AUTH'
		authenticationFlowType: Config.authenticationFlowType !== undefined ? Config.authenticationFlowType : "USER_PASSWORD_AUTH",
	},
};

// If we have in parameter that means start a PKCE or Implict flow
// We switch the clientId to submit the one from the client website
let queryStringParams = new URLSearchParams(window.location.search);
let clientId = queryStringParams.get("client_id");
if (clientId) {
	// We save the client ID, our Amplify auth will be based on that one
	localStorage.setItem("client-id", clientId);
} else {
	// If there is no client-Id in query, we set back the last one used for login
	// it may be undefined if we never logged in
	clientId = localStorage.getItem("client-id");
}
if (clientId) {
	// We configure the Amplify Auth component in the context of using a client website client-id
	// if no clientId is found (direct login not from a client) the web client id of the broker will be used as default
	amplifyConfig.aws_user_pools_web_client_id = clientId;
}
Amplify.configure(amplifyConfig);

ReactDOM.render(
	<React.StrictMode>
		<Authenticator.Provider>
			<Router>
				<Routes>
					<Route path="/*" element={<App />} />
					<Route path="/refresh-token" element={<RefreshToken />} />
				</Routes>
			</Router>
		</Authenticator.Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.unregister();
