import { fetchAuthSession, signOut } from "aws-amplify/auth";
import Loader from "./components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

const RefreshToken = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { route, toSignUp } = useAuthenticator(context => [context.route]);

    useEffect(() => {
        const checkAuthState = async () => {
            const session = await fetchAuthSession({ forceRefresh: true });
            const queryParams = new URLSearchParams(location.search);
            const redirect_uri = queryParams.get('redirect_uri');
            const from_window = queryParams.get('from_window') == "true";
            const refresh_token = queryParams.get('refresh_token') == "true";
            // Fa redirect alla Registrazione
            const id_token = session?.tokens?.idToken.toString();
            const access_token = session?.tokens?.accessToken?.toString();
            const email = session?.tokens?.idToken.payload?.email;
            const username = session?.tokens?.idToken.payload?.["cognito:username"];

            if (access_token) {
                const link_redirect = `/callback-mobile-app?id_token=${id_token}&access_token=${access_token}&email=${email}&username=${username}`;
                window.location.replace(link_redirect)
            } else {
                signOut();
                window.location.replace("/")
            }


            console.log(`checkAuthState: ${JSON.stringify({ route, access_token })}`)

            try {

            } catch (err) {
                console.log("errr", err)
            }
        };

        checkAuthState();
    }, [location, navigate, route]);

    return (
        <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loader />
        </div>
    )
}
export default RefreshToken;